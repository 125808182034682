<template>
  <v-main>
    <v-row gutters="2" class="pt-0 pb-0">
      <v-col cols="12" md="12" class="pt-0 pb-0">
        <v-card>
          <v-card-text>
            <v-row no-gutters justify="space-around">
              <v-col cols="12" md="12">
                <TableFeatureProduct
                  @validateRequest="showConfirmation"
                ></TableFeatureProduct>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="isShowValidation" max-width="900" persistent>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <CountrySelect
                :key="keyDialog"
                :form-data.sync="countriesSelected"
                :data.sync="countriesLoaded"
              ></CountrySelect>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="hideConfirmation()">
            {{ $t("MESSAGE.DELETE.MODAL_NO") }}
          </v-btn>

          <v-btn color="primary darken-1" text @click="updateCourses()">
            {{ $t("MESSAGE.DELETE.MODAL_YES") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TableFeatureProduct from "@/view/pages/superadmin/feature_product/components/Table";
import CountrySelect from "@/view/content/components/CountrySelect";
import { mapActions, mapGetters } from "vuex";
const STATUS_PENDING_YNSITU = 3;
const UPDATE_AGREE_YNSITU = "updateReviewAgree";
export default {
  name: "list-feature-product",
  components: { TableFeatureProduct, CountrySelect },
  data() {
    return {
      keyDialog: 0,
      keyTable: 0,
      isShowValidation: false,
      methodSelected: null,
      productId: null,
      countriesSelected: {
        africa: [],
        asia: [],
        europe: [],
        north_america: [],
        oceania: [],
        south_america: [],
      },
      countriesLoaded: {
        AFRICA: [],
        ASIA: [],
        EUROPE: [],
        N_AMER: [],
        OCEAN: [],
        S_AMER: [],
      },
      filterList: {
        where: {
          status: STATUS_PENDING_YNSITU,
        },
      },
    };
  },
  mounted() {
    this.methodSelected = "getCourses";
    this.updateCourses();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("SUPERADMIN.TITLE_FEATURE_PRODUCT") },
    ]);
  },
  computed: {
    ...mapGetters(["courses"]),
    TitleValidate() {
      if (this.methodSelected === UPDATE_AGREE_YNSITU) {
        return this.$t("SUPERADMIN.YOU_ARE_SURE_AGREE");
      } else {
        return this.$t("SUPERADMIN.YOU_ARE_SURE_DENY");
      }
    },
  },
  methods: {
    ...mapActions([
      "getCoursesServerSide",
      "updateCourseFeatured",
      "getCourseFeatured",
    ]),
    showConfirmation({ method, productId }) {
      this.methodSelected = method;
      this.productId = productId;
      this.getCourseFeatured(productId).then(response => {
        if (response && response.length > 0) {
          response?.map(item => {
            this.countriesLoaded[item.Continent].push({
              countryId: item.countryId,
              nameTranslation: item.Country,
            });
          });
        }

        this.keyDialog += 1;
        this.isShowValidation = true;
      });
    },
    hideConfirmation() {
      this.methodSelected = null;
      this.productId = null;
      this.countriesLoaded = {
        AFRICA: [],
        ASIA: [],
        EUROPE: [],
        N_AMER: [],
        OCEAN: [],
        S_AMER: [],
      };
      this.keyDialog += 1;
      this.isShowValidation = false;
    },
    updateCourses() {
      switch (this.methodSelected) {
        case "getCourses":
          this.getCoursesServerSide();
          break;
        case "addCountries":
          this.isShowValidation = false;
          this.updateCourseFeatured({
            countries: this.countriesSelected,
            productId: this.productId,
          }).then(() => {
            this.methodSelected = null;
            this.productId = null;
            this.$bvToast.toast(this.$t("MESSAGE.UPDATE.DESCRIPTION_NEW"), {
              title: this.$t("MESSAGE.UPDATE.TITLE_NEW"),
              variant: "success",
              solid: true,
            });
          });
          break;
      }
      this.keyTable += 1;
      this.hideConfirmation();
    },
  },
};
</script>
