<template>
  <v-card>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('SUPERADMIN.SEARCH')"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="courses"
      :search="search"
      :loading="loading"
      multi-sort
    >
      <template v-slot:[`item.date`]="{ item }">
        {{ item.created_at | moment(campus.date_format) }}
      </template>
      <template v-slot:[`item.ACTIONS`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              @click="isShowValidation('addCountries', item.productId)"
            >
              mdi-format-list-checks
            </v-icon>
          </template>
          <span>{{ $t("SUPERADMIN.FEATURE_PRODUCT") }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.countriesFeatured`]="{ item }">
        {{ item.countriesFeatured || 0 }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      titleConfirmation: null,
      search: null,
      loading: false,
      totalCourses: 0,
      options: {},
      headers: [
        {
          text: this.$i18n.t("SUPERADMIN.NAME"),
          value: "name",
        },
        {
          text: this.$i18n.t("SUPERADMIN.LANGUAGE"),
          value: "Language",
        },
        {
          text: this.$i18n.t("SUPERADMIN.CATEGORY"),
          value: "Category",
        },
        {
          text: this.$i18n.t("SUPERADMIN.CAMPUS"),
          value: "Campus",
        },
        {
          text: this.$i18n.t("SUPERADMIN.CITY"),
          value: "City",
        },
        {
          text: this.$i18n.t("SUPERADMIN.COUNTRY"),
          value: "Country",
        },
        {
          text: this.$i18n.t("SUPERADMIN.N_COUNTRIES_FEATURED"),
          value: "countriesFeatured",
        },
        { text: this.$i18n.t("SUPERADMIN.ACTIONS"), value: "ACTIONS" },
      ],
    };
  },
  computed: {
    ...mapGetters(["courses", "campus"]),
  },
  methods: {
    isShowValidation(method, productId = "") {
      this.$emit("validateRequest", { method, productId });
    },
  },
};
</script>
